<template>
  <div class="home" :class="{ mobile: $vuetify.breakpoint.smAndDown }">
    <!-- stories section -->
    <div class="section px-md-16">
      <v-container class="px-8 px-md-12 px-lg-16 py-xl-16">
        <h2 class="section-title py-xl-8">Storie</h2>
        <v-row>
          <v-col
            class="col-12 col-md-4"
            v-for="storia in storieList"
            :key="storia.id"
          >
            <storie-card :storia="storia"></storie-card>
          </v-col>
        </v-row>
        <v-row class="py-xl-8">
          <v-btn
            color="#29CED3"
            class="section-button ml-3 my-8"
            @click="$router.push(storieRoute)"
            >Tutte le storie</v-btn
          >
        </v-row>
      </v-container>
    </div>

    <!-- yucca section -->
    <div class="yucca section py-0">
      <v-container class="py-16 pa-sm-16">
        <v-row class="col-md-8 col-lg-6 py-16">
          <p class="ml-3 pt-xl-16 pb-xl-8">
            Yucca è la smart data platform a disposizione di amministrazioni e
            imprese per sviluppare nuove applicazioni IoT e Big Data
          </p>
          <v-btn class="ml-3 mb-xl-16 my-8" color="#29CED3">
            <a
              class="section-button"
              @click="$router.push('yucca')"
              rel="noopener noreferrer"
              >{{ yucca.button.name }}</a
            >
          </v-btn>
        </v-row>
      </v-container>
    </div>

    <!-- come fare per section -->
    <div class="come-fare section px-md-16">
      <v-container class="px-8 px-md-12 px-lg-16 py-xl-16">
        <h2 class="section-title">Come Fare per</h2>
        <v-row>
          <v-col class="col-12 px-sm-10 px-lg-16 col-sm-4 text-center">
            <div class="come-fare-item">
              <img
                class="img-max-w250"
                :src="require('@/assets/come_fare_per_ricercare.svg')"
                alt=""
              />
              <p class="px-4 pt-4 come-fare-description">
                Scopri come ricercare e scaricare i contenuti nel catalogo
              </p>
              <router-link :to="ricercareRoute">
                <span class="sr-only"
                  >Per saperne di pi&ugrave; su come ricercare e scaricare i
                  contenuti nel catalogo</span
                >
              </router-link>
            </div>
          </v-col>
          <v-col class="col-12 px-sm-10 px-lg-16 col-sm-4 text-center">
            <div class="come-fare-item">
              <img
                class="img-max-w250"
                alt=""
                :src="require('@/assets/come_fare_per_ricuso.svg')"
              />
              <p class="px-4 pt-4 come-fare-description">
                Scopri come fare il riuso dei dati
              </p>
              <router-link :to="riusareRoute">
                <span class="sr-only"
                  >Per saperne di pi&ugrave; su come fare il riuso dei
                  dati</span
                >
              </router-link>
            </div>
          </v-col>
          <v-col class="col-12 px-sm-10 px-lg-16 col-sm-4 text-center">
            <div class="come-fare-item">
              <img
                class="img-max-w250"
                alt=""
                :src="require('@/assets/come_fare_per_pubblicare.svg')"
              />
              <p class="px-4 pt-4 come-fare-description">
                Sei un Ente e vorresti pubblicare i tuoi dati aperti? Scopri
                come aderire al portale
              </p>
              <router-link :to="aderireRoute">
                <span class="sr-only"
                  >Per saperne di pi&ugrave; su Sei un Ente e vorresti
                  pubblicare i tuoi dati aperti? Scopri come aderire al
                  portale</span
                >
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- enti section -->
    <div class="enti section">
      <div class="py-8">
        <v-container class="py-16">
          <v-row class="col-md-8 pa-sm-16">
            <p class="ml-3">
              Scopri gli Enti locali e gli altri soggetti piemontesi che
              aderiscono e pubblicano i propri dati aperti
            </p>
            <v-btn
              color="#29CED3"
              class="section-button ml-3 my-8"
              @click="$router.push(enti.button.route)"
              >Scopri di più</v-btn
            >
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- data viz section -->
    <div class="section dataviz px-md-16">
      <v-container class="px-8 px-md-12 px-lg-16 pt-xl-16">
        <h2 class="section-title py-xl-8">Data viz</h2>
        <v-row>
          <v-col
            class="col-12 col-md-4"
            v-for="dataViz in dataVizList"
            :key="dataViz.titolo"
          >
            <dataviz-card :dataViz="dataViz"></dataviz-card>
          </v-col>
        </v-row>
        <v-row class="pt-xl-8">
          <v-btn
            color="#29CED3"
            class="section-button ml-3 my-8"
            @click="$router.push(datavizRoute)"
            >Vedi tutte</v-btn
          >
        </v-row>
      </v-container>
    </div>

    <!-- news section -->
    <div class="section news px-md-16">
      <v-container class="px-8 px-md-12 px-lg-16 py-16">
        <h2 class="section-title pt-16 pt-sm-4 py-xl-8">News</h2>
        <v-row>
          <v-col
            class="col-12 col-md-4"
            v-for="news in newsList"
            :key="news.id"
          >
            <news-card :news="news"></news-card>
          </v-col>
        </v-row>
        <v-row class="py-xl-8">
          <v-btn
            color="#29CED3"
            class="section-button ml-3 my-8"
            @click="$router.push(newsRoute)"
            >Tutte le news</v-btn
          >
        </v-row>
      </v-container>
    </div>

    <!-- twitter section -->
    <div class="section twitter px-md-16">
      <v-container class="px-8 px-md-12 px-lg-16 py-xl-16">
        <v-row>
          <v-col class="col-12 col-md-auto">
            <img
              class="img-max-w-200"
              :src="require('@/assets/Twitter_home_icon.svg')"
            />
          </v-col>
          <v-col class="col-12 col-md-auto">
            <v-row>
              <p class="twitter-account">@datipiemonte</p>
            </v-row>
              <timeline
                id="OpenDataPiemont"
                sourceType="profile"
                data-consent="twitter"
                data-block="show"
                :options="{
                  dnt: true,
                  width: 600,
                  height: 400,
                  chrome: 'nofooter, noheader, transparent',
                }">
                <p class="cc-block-placeholder">
                  Per visualizzare questa sezione &egrave; necessario	<a href="#" class="ccb__edit">Abilitare i Cookie</a>
                </p>
              </timeline>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import NewsCard from "../components/NewsCard"
import StorieCard from "../components/StorieCard"
import DatavizCard from "../components/DatavizCard"
import { Timeline } from "vue-tweet-embed"
import {
  getStorie,
  getDataViz,
  getNews,
  getHomeTagsList,
  getHomeTopicsList,
  getHomeOwnersList,
} from "../services/api"
import {
  ENTI,
  STORIE,
  DATAVIZ,
  NEWS,
  RICERCARE,
  RIUSARE,
  ADERIRE,
  YUCCA
} from "../router/routes"
export default {
  name: "Home",
  components: {
    NewsCard,
    StorieCard,
    DatavizCard,
    Timeline
  },
  data () {
    return {
      yucca: {
        button: {
          name: "Scopri YUCCA smart data platform",
        },
      },
      enti: {
        button: {
          name: "Scopri di più",
          route: { name: ENTI.name },
        },
      },
    }
  },
  async created () {
    this.$emit("setLoading", true)

    let tagListParams = { limit: 15 }
    let tagListPromise = getHomeTagsList(tagListParams)
    let topicsListPromise = getHomeTopicsList()
    let ownersListPromise = getHomeOwnersList()

    if (this.homeTagList.length == 0) {
      try {
        let { data } = await tagListPromise
        this.$store.dispatch("setHomeTagList", {
          tagList: data.terms,
        })
      } catch (err) {
        console.log(err)
      }
    }

    if (this.homeTopicsList.length == 0) {
      try {
        let { data } = await topicsListPromise
        this.$store.dispatch("setHomeTopicsList", {
          topicsList: data,
        })
      } catch (err) {
        console.log(err)
      }
    }

    if (this.homeOwnersList.length == 0) {
      try {
        let { data } = await ownersListPromise
        this.$store.dispatch("setHomeOwnersList", {
          ownersList: data,
        })
      } catch (err) {
        console.log(err)
      }
    }

    let storiePromise = getStorie()
    let dataVizPromise = getDataViz()
    let newsPromise = getNews()

    // dataviz
    if (this.dataVizList.length == 0) {
      try {
        let { data } = await dataVizPromise

        this.$store.dispatch("setDataVizList", {
          dataVizList: data,
        })
      } catch (err) {
        console.log(err)
      }
    }

    // storie
    if (this.storieList.length == 0) {
      try {
        let { data } = await storiePromise
        this.$store.dispatch("setStorieList", {
          storieList: data,
        })
      } catch (err) {
        console.log(err)
      }
    }

    // news
    if (this.newsList.length == 0) {
      try {
        let { data } = await newsPromise

        this.$store.dispatch("setNewsList", {
          newsList: data,
        })
      } catch (err) {
        console.log(err)
      }
    }

    let iconList = document.querySelectorAll("svg")
    iconList.forEach((icon) => {
      icon.setAttribute("width", "80")
      icon.setAttribute("height", "63")
    })

    this.$emit("setLoading", false)
  },
  computed: {
    homeTopicsList () {
      return this.$store.getters["getHomeTopicsList"]
    },
    homeTagList () {
      return this.$store.getters["getHomeTagsList"]
    },
    homeOwnersList () {
      return this.$store.getters["getHomeOwnersList"]
    },
    dataVizList () {
      return this.$store.getters["getDataVizList"].slice(0, 3)
    },
    storieList () {
      return this.$store.getters["getStorieList"].slice(0, 3)
    },
    newsList () {
      let list = this.$store.getters["getNewsList"]
        .filter((r) => {
          return r.in_home == true
        })
        .slice(0, 3)
      return list
    },
    storieRoute () {
      return { name: STORIE.name }
    },
    datavizRoute () {
      return { name: DATAVIZ.name }
    },
    newsRoute () {
      return { name: NEWS.name }
    },
    ricercareRoute () {
      return { name: RICERCARE.name }
    },
    riusareRoute () {
      return { name: RIUSARE.name }
    },
    aderireRoute () {
      return { name: ADERIRE.name }
    },
    yuccaRoute () {
      return { name: YUCCA.name }
    },
  },
  mounted () {
    this.$store.dispatch("createHome")
  },
  destroyed () {
    this.$store.dispatch("destroyHome")
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.home {
  a {
    text-decoration: none;
  }
  .section {
    padding-top: 15px;
    padding-bottom: 15px;

    .section-title {
      font-weight: bold;
      font-size: 34px;
      margin-bottom: 16px;
    }
    .section-button {
      font-weight: 700;
      letter-spacing: 0;
      color: $text-dark;
    }
  }
  .yucca {
    font-size: 28px;
    color: $text-white;
    background: url("../assets/sfondo_yucca.png") no-repeat center center;
    background-size: cover;
    min-height: 750px;
  }
  .come-fare {
    background-color: #57d9e8;
    .come-fare-description {
      font-weight: 600;
      font-size: 16px;
      color: $text-dark;
    }

    .come-fare-item {
      position: relative;

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
      }
    }
  }
  .enti {
    font-size: 28px;
    color: $text-white;
    background: url("../assets/sfondo_enti.png") no-repeat center center;
    background-size: cover;
    min-height: 750px;
  }
  .news {
    background-image: url("../assets/sfondo_news.png");
    background-size: 100% 100%;
  }
  .twitter {
    .twitter-account {
      color: #2f4595;
      font: normal normal 600 30px/45px Open Sans;
    }
  }
  .mobile {
    .stories-title {
      font-size: 24px;
    }
    .news {
      background-size: cover;
    }
    .yucca {
      font-size: 24px;
      background-size: cover;
      background-image: url("../assets/sfondo_yucca.png");
    }
    .enti {
      background-size: cover;
    }
  }
}
img.img-max-w250{max-height:250px;
  @media (max-width: 1199.98px) {
     max-height:200px;
     }
}
img.img-max-w-200{max-width:200px;}

</style>
