<template>
    <v-card height="320" class="dataviz-card">
      <div class="container-img">
      <img :src="baseUrl + imgUrl" :alt="displayTitolo" class="img-generic-100" />
      </div>
      <div class="card-section pt-1">
        <v-card-text class="dataviz-title">{{displayTitolo}}</v-card-text>
      </div>
      <router-link :to="route"><span class="sr-only">Per saperne di pi&ugrave; su {{displayTitolo}}</span></router-link>
    </v-card>
</template>

<script>
import { DATAVIZ_DETAIL } from "../router/routes";

export default {
  name: "DatavizCard",
  props: {
    dataViz: { type: Object, required: false },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    id() {
      return this.dataViz?.id ?? "";
    },
    route() {
      return {name: DATAVIZ_DETAIL.name, params: {id: this.id}};
    },
    titolo() {
      return this.dataViz?.titolo ?? "";
    },
    displayTitolo() {
      return this.titolo.length < 50 ? this.titolo : this.titolo.substring(0, 45) + '...';
    },
    imgUrl() {
      return this.dataViz?.url_relativa_img ?? "";
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.dataviz-title {
  font: normal normal 600 21px/30px Open Sans;
  letter-spacing: 0px;
  word-break: break-word;
}

.dataviz-card{

  position:relative;

  a{

    position: absolute;
    width:100%;
    height:100%;
    display: block;
    top:0;
  }
}
</style>